<template>
  <!-- <img :src="url" alt="logo"> -->
  <b-link class="brand-logo">
    <img
      :src="appLogoUrl"
      alt="logo"
    >
    <h2 class="brand-text text-primary ml-1 mt-1">
      {{ appTitle }}
    </h2>
  </b-link>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      appTitle: $themeConfig.app.appName,
      appLogoUrl: $themeConfig.app.appLogoImage,
    }
  },
}
</script>
